import { Switch, Tag } from "antd";
import { ActionIcons } from "./Components";
import dayjs from "dayjs";

export const SubscriptionPlansColumns = (handleView, handleEdit, handleDelete, handleStatus) => {
    return [
        {
            title: "S. No",
            key: "userId",
            dataIndex: "id"
        },

        {
            title: "Plan Name",
            key: "name",
            render: (res) => <span>{`${res?.name}`}</span>
        },
        {
            title: "Plan Type",
            key: "planType",
            render: (res) => <span>{res?.subscriptionBy ? 'Subscription' : 'Package'}</span>
        },
        {
            title: "Parent Plan",
            key: "discountPackage",
            render: (res) => 
                    <div>{res?.discountPackage ? <Tag color={"#A49786"}>{res?.discountPackage?.name}</Tag> : 'N/A' }</div>
            // render: (res) => <span>{`${res?.name}`}</span>
        },

        {
            title: "Price",
            key: "price",
            render: (res) => <span>{res?.free === '1' ? 'Free' : res?.discountPrice ?  `$${res?.discountPrice}` : `$${res?.price}` }</span>
        },

        {
            title: "Credits",
            key: "credits",
            render: (res) => <span>{res?.subscriptionBy ? "Unlimited" : `${res?.credits} Event Credit`}</span>
        },
        {
            title: "Subscribed Users",
            key: "subcribedUsers",
            render: (res) => <span>{res?.subcribedUsers}</span>
        },
        {
            title: "Status",
            key: "status",
            render: (res) => (
                <Switch
                    checked={parseInt(res?.status) > 0 ? true : false}
                    onChange={() => handleStatus(res?.status, res?.id)}
                />
            )
        },
        {
            title: "Actions",
            key: "actions",
            render: (res) => (
                <ActionIcons
                    onView={() => handleView(res.id, res)}
                    onEdit={() => handleEdit(res)}
                    onDelete={() => handleDelete(res.id)}
                />
            )
        }
    ];
};
export const SubscriptionPlansDetailColumns = () => {
    const subscriptionDurations = [
        { key: "Weekly", value: "Weekly" },
        { key: "1 Month", value: "OneMonth" },
        { key: "2 Months", value: "TwoMonth" },
        { key: "3 Months", value: "ThreeMonth" },
        { key: "6 Months", value: "SixMonth" },
        { key: "1 Year", value: "OneYear" }
    ];
    return [
        {
            title: "S. No",
            key: "userId",
            dataIndex: "id"
        },

        {
            title: "Name",
            key: "name",
            render: (res) => <span>{`${res?.user?.firstName} ${res?.user?.lastName}` || "N/A"}</span>
        },

        {
            title: "Email",
            key: "email",
            render: (res) => <span>{res?.user?.email || "N/A"}</span>
        },

        {
            title: "Contact",
            key: "contact",
            render: (res) => <span>{res?.user?.contact || "N/A"}</span>
        },
        {
            title: "State",
            key: "state",
            render: (res) => <span>{res?.user?.state?.name || "N/A"}</span>
        },
        {
            title: "City",
            key: "city",
            render: (res) => <span>{res?.user?.city?.name || "N/A"}</span>
        },
        {
            title: "Subscription Duration",
            key: "subcriptionBy",
            render: (res) => {
                const matchingDuration = subscriptionDurations.find((item) => item.value === res?.user?.subcriptionBy);
                return <span>{matchingDuration ? matchingDuration.key : "N/A"}</span>;
            }
        },
        {
            title: "Purchase Date",
            key: "purchaseDate",
            render: (res) => (
                <span> {res?.user?.purchaseDate ? dayjs(res?.user?.purchaseDate).format("MM-DD-YYYY") : "N/A"}</span>
            )
        },
        {
            title: "Expiry Date",
            key: "expirationDate",
            render: (res) => (
                <span>{res?.user?.expirationDate ? dayjs(res?.user?.expirationDate).format("MM-DD-YYYY") : "N/A"}</span>
            )
        }
    ];
};
export const dataSource = [
    {
        id: "01",
        pkgName: "1 Wedding",
        price: "$9.99",
        credits: "1 Event Credit",
        subscribedUsers: 20,
        status: true
    },
    {
        id: "02",
        pkgName: "5 Weddings",
        price: "$9.99",
        credits: "1 Event Credit",
        subscribedUsers: 30,

        status: false
    },
    {
        id: "03",
        pkgName: "15 Wedding",
        price: "$9.99",
        credits: "1 Event Credit",
        subscribedUsers: 40,
        status: true
    },
    {
        id: "04",
        pkgName: "30 Wedding",
        price: "$9.99",
        credits: "1 Event Credit",
        subscribedUsers: 40,
        status: false
    },
    {
        id: "05",
        pkgName: "50 Weddings",
        price: "$29.99",
        credits: "1 Event Credit",
        subscribedUsers: 40,
        status: false
    },
    {
        id: "06",
        pkgName: "Unlimited Wedding",
        price: "$39.99",
        credits: "1 Event Credit",
        subscribedUsers: 40,
        status: true
    },
    {
        id: "07",
        pkgName: "5 Weddings",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 40,
        status: true
    },
    {
        id: "08",
        pkgName: "30 Wedding",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 20,
        status: true
    },
    {
        id: "09",
        pkgName: "15 Wedding",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 20,
        status: true
    },
    {
        id: "10",
        pkgName: "5 Weddings",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 36,
        status: true
    },
    {
        id: "11",
        pkgName: "1 Wedding",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 25,
        status: true
    },
    {
        id: "12",
        pkgName: "5 Weddings",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 30,
        status: false
    },
    {
        id: "13",
        pkgName: "15 Wedding",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 40,
        status: true
    },
    {
        id: "14",
        pkgName: "30 Wedding",
        price: "$49.99",
        credits: "1 Event Credit",
        subscribedUsers: 20,
        status: false
    }
];
export const DataSubscriptionDetail = [
    {
        id: "01",
        name: "Neal Matthews",
        email: "nathan.roberts@example.com",
        contact: "+1 (505) 555-0125",
        state: "Herbert Hoover",
        city: "Fairfield"
    },
    {
        id: "02",
        name: "Jamal Burnett",
        email: "alma.lawson@example.com",
        contact: "+1 (904) 555-1010",
        state: "James Monroe",
        city: "Toledo"
    },
    {
        id: "03",
        name: "Juan Mitchel",
        email: "bill.sanders@example.com",
        contact: "+1 (808) 555-0111",
        state: "Andrew Jackson",
        city: "Austin"
    },
    {
        id: "04",
        name: "Barry Dick",
        email: "jackson.graham@example.com",
        contact: "+1 (217) 555-0113",
        state: "John F. Kennedy",
        city: "Orange"
    },
    {
        id: "05",
        name: "Ronald Taylor",
        email: "kenzi.lawson@example.com",
        contact: "+1 (229) 555-0109",
        state: "Dwight D. Eisenhower",
        city: "Pembroke Pines"
    },
    {
        id: "06",
        name: "Jacob Hunter",
        email: "willie.jennings@example.com",
        contact: "+1 (225) 555-0118",
        state: "Millard Fillmore",
        city: "Naperville"
    }
];
export const statusOptions = [
    { label: "All", value: "" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" }
];

