import React, { useState } from "react";
import { Button, Checkbox, Col, Form, Input, notification, Row, Typography } from "antd";
import quotes from "../../assets/all-svg-files/quotes.svg";
import logo from "../../assets/images/new-logo.png";
import facebookIcon from "../../assets/all-svg-files/facebook-icon.svg";
import twitterIcon from "../../assets/all-svg-files/twitter-icon.svg";
import googleIcon from "../../assets/all-svg-files/google-icon.svg";
import { authenticatedRoutesConstant, socialMediaColors, userInfo } from "util/util.constant";
import "./Login.less";
import IconCircle from "Components/IconCircle/IconCircle";
import { UserService } from "services/users.service";

const { Text } = Typography;

function Login() {
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();
    const onFinish = async (values) => {
        setloading(true);
        try {
            const response = await UserService.login(values);
            if (response) {
                const apiKey = response?.token;
                localStorage.setItem(userInfo.TOKEN, apiKey);
                localStorage.setItem(userInfo.USER, JSON.stringify(response?.user));
                window.location.href = authenticatedRoutesConstant.Dashboard;
            }
            setloading(false);
        } catch (error) {
            notification.warn({
                message: "Email or password is incorrect!"
            });
            setloading(false);
        }
    };

    return (
        <div className="login-container">
            <Row>
                <Col md={18} sm={0} xs={0} className="login-container__caption">
                    <div className="login-container__caption__container">
                        <div className="login-container__caption__container__title mb-4">
                            <img src={quotes} alt="quotes" />
                            <span className="ml-2">
                                <span className="login-container__colored">5k</span>
                                <span>+ Satisfied clients</span>
                            </span>
                        </div>

                        <div className="login-container__caption__container__text">
                            <p className="login-container__caption__container__text-paragraph">
                                "Fantastic theme with a ton of options. If you just want the HTML to integrate with your
                                project, then this is the package. You can find the files in the 'dist' folder...no need
                                to install git and all the other stuff the documentation talks about."
                            </p>
                        </div>

                        <div className="login-container__caption__container__author">
                            <div className="login-container__colored ">Abs1981</div>

                            <div>- TWA User</div>
                        </div>
                    </div>
                </Col>

                <Col md={6} sm={24} xs={24} className="login-container__information">
                    <div className="login-container__information__logo-container">
                        <div className="login-container__information__logo-container__logo">
                            <img src={logo} alt="logo" />
                        </div>
                    </div>
                    <h1 className="login-container__information__title">Welcome Back!</h1>
                    <p className="login-container__information__subtitle">Sign in to continue.</p>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="off"
                        className="custom-form-container"
                        form={form}
                    >
                        <Text>Username</Text>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your email!"
                                }
                            ]}
                        >
                            <Input placeholder="Enter username" autoComplete="off" />
                        </Form.Item>

                        <Text>Password</Text>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!"
                                }
                            ]}
                        >
                            <Input.Password placeholder="Enter password" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            name="rememberMe"
                            rules={[
                                {
                                    required: false
                                }
                            ]}
                        >
                            {/* <Input.Password placeholder="Enter password" autoComplete="off" /> */}
                            <Checkbox />
                            <Text className="ml-point-5">Remember me</Text>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                className="login-container__information__btn"
                            >
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="login-container__information__sign-in__container">
                        <Text className="login-container__information__sign-in__container__text">Sign in with</Text>
                        <div className="login-container__information__sign-in__container__icons">
                            <IconCircle
                                height="3.2rem"
                                width="3.2rem"
                                icon={facebookIcon}
                                alt="facebook-icon"
                                backgroundColor={socialMediaColors.facebook}
                            />

                            <IconCircle
                                height="3.2rem"
                                width="3.2rem"
                                icon={twitterIcon}
                                alt="twitter-icon"
                                backgroundColor={socialMediaColors.twitter}
                                className="ml-1 mr-1"
                            />

                            <IconCircle
                                height="3.2rem"
                                width="3.2rem"
                                icon={googleIcon}
                                alt="google-icon"
                                backgroundColor={socialMediaColors.google}
                            />
                        </div>
                    </div>
                    {/* <div className="login-container__information__sign-up__container">
                        <span>Don't have an account?</span>
                        <Link to={unAuthenticatedRoutesConstant.Register}> Signup now</Link>
                    </div> */}

                    <div className="login-container__information__trademark">© 2023 The Wedding App.</div>
                </Col>
            </Row>
        </div>
    );
}

export default Login;
