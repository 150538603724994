import { DashboardService } from "services/dashboard.service";
import { handleErrorResponse } from "util/errorHandling.util";

const { useMemo } = require("react");
const { useQuery, useMutation } = require("react-query");
const { UserService } = require("services/users.service");
const { usePrefetchPaginationWithReactQuery } = require("./general.queries");
const { QueryNames, globalReactQueryGetOption, PAGINATION_STALE_TIME } = require("./queries.constant");

function useQueryUsers(payload) {
    const queryName = [QueryNames.users, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await UserService.getUsers(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        staleTime: !payload?.isPagination ? 0 : PAGINATION_STALE_TIME,
        keepPreviousData: true
    });

    usePrefetchPaginationWithReactQuery(
        queryReturnedValues,
        payload?.page,
        QueryNames.envelopes,
        () =>
            UserService.getUsers({
                ...queryFnParams,
                page: payload?.page + 1
            }),
        payload?.isPagination
    );

    return queryReturnedValues;
}

function useQueryDashboardAnalytics() {
    const queryName = [QueryNames.dashboard];
    const queryFn = async () => await DashboardService.getAnalytics();

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}

function useQueryUpdateUser(onSuccess, onError) {
    const queryFn = async ({ payload, userId }) => await UserService.updateUser(userId, payload);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {

                onSuccess(data);
            }
        }
    });
}
// Update Guest Details
function useMutateUpdateGuestDetail(onSuccess, onError) {
    const queryFn = async ({ payload, userId }) => await UserService.updateGuestDetail(userId, payload);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}
// Update Users Password
function useMutateUpdateUserPassword(onSuccess, onError) {
    const queryFn = async ({ payload, userId }) => await UserService.updateUsersPassword(userId, payload);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}

export { useQueryDashboardAnalytics, useQueryUpdateUser, useQueryUsers,useMutateUpdateGuestDetail,useMutateUpdateUserPassword };

