import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import withClearCache from "./ClearCache";
import store from "./redux/configureStore";
import CustomSpinner from "Components/CustomSpinner/CustomSpinner";
import "./assets/vendor/style";
import "./index.css";
import { serviceWorkerRegistration } from "serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: 0,
            staleTime: 5 * 1000
        }
    }
});

const NextAppComponent = () => (
    <React.Suspense fallback={<CustomSpinner />}>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    </React.Suspense>
);

const ClearCacheComponent = withClearCache(NextAppComponent);
root.render(<ClearCacheComponent />);

serviceWorkerRegistration.register();

reportWebVitals();

