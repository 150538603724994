export const QueryModuleNames = {
    categories: "categories",
    medias: "medias",
    users: "users",
    dashboard: "dashboard",
    subscriptionPlanPackagesList: "subscriptionPlanPackagesList",
    subscriptionPlan: "subscriptionPlan",
    RefferalDetailByID: "RefferalDetailByID",
    refferalCodeEligiblePlans: "refferalCodeEligiblePlans",
    getBaseSubscriptions: "getBaseSubscriptions",
    getRefferalList: "getRefferalList",
};

export const QueryNames = {
    categories: [QueryModuleNames.categories],
    medias: [QueryModuleNames.medias],
    users: [QueryModuleNames.users],
    dashboard: [QueryModuleNames.dashboard],
    subscriptionPlanPackagesList: [QueryModuleNames.subscriptionPlanPackagesList],
    subscriptionPlan: [QueryModuleNames.subscriptionPlan],
    subscriptionPlanByUsers: [QueryModuleNames.subscriptionPlanByUsers],
    RefferalDetailByID: [QueryModuleNames.RefferalDetailByID],
    refferalCodeEligiblePlans: [QueryModuleNames.refferalCodeEligiblePlans],
    getBaseSubscriptions: [QueryModuleNames.getBaseSubscriptions],
    getRefferalList: [QueryModuleNames.getRefferalList],
};

export const globalReactQueryGetOption = {
    refetchOnMount: "always"
};

export const PAGE_SIZE = 10;

export const PAGINATION_STALE_TIME = 5000;

