const { ApiRequestUtil } = require("util/api.request.util");
const { ApiService } = require("util/api.service");


const refferalcodeServiceUrl = {
    create: "/refferal-code",
    discountedPlansList:"/subscriptions-plans/elgible-plans",
    discountedChildPlansList:"/subscriptions-plans/discounted-sub",
    refferalListing:"/refferal-code",
    delete:'/refferal-code',
    detailByID:'/refferal-code',
    editRefferal:'/refferal-code',
}

const CreateRefferalCode = async (payload) => {
    const response = await ApiService.post(refferalcodeServiceUrl.create, payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const EligiblePlansList = async () => {
    const response = await ApiService.get(refferalcodeServiceUrl.discountedPlansList);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const EligibleChildPlansListing = async (id) => {
    const response = await ApiService.get(`${refferalcodeServiceUrl.discountedChildPlansList}/${id}`);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const RefferalList = async (payload) => {
    const response = await ApiService.get(refferalcodeServiceUrl.refferalListing, payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const deleteRefferalDetailItem = async (id) => {
    const response = await ApiService.deleteRequest(`${refferalcodeServiceUrl.delete}/${id}`);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const RefferalDetailByID = async (id,payload) => {
    const response = await ApiService.get(`${refferalcodeServiceUrl.detailByID}/${id}/get-users`,payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const editRefferal = async (id, data) => {
    const response = await ApiService.patch(`${refferalcodeServiceUrl.editRefferal}/${id}`, data);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
export const RefferalCodeService = {
    CreateRefferalCode,
    EligiblePlansList,
    RefferalList,
    deleteRefferalDetailItem,
    RefferalDetailByID,
    editRefferal,
    EligibleChildPlansListing
};