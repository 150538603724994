import { Spin } from "antd";
import IconCircle from "Components/IconCircle/IconCircle";
import React from "react";
import { primaryColor } from "util/util.constant";
import "./DashboardCard.less";

const DashboardCard = ({ cardInfo, analyticsLoading, analytics }) => {
    return (
        <div className="card-container">
            <div className="card-container__information">
                <h5 className="card-container__information__title">{cardInfo?.title}</h5>
                <Spin spinning={analyticsLoading}>
                    <span className="card-container__count">{analytics && analytics[cardInfo?.key]}</span>
                </Spin>
            </div>
            <div className="card-container__icon">
                <IconCircle icon={cardInfo?.icon} height="5.3rem" width="5.3rem" backgroundColor={primaryColor} />
            </div>
        </div>
    );
};

export default DashboardCard;
