const { ApiRequestUtil } = require("util/api.request.util");
const { ApiService } = require("util/api.service");

const subscriptionPlanServiceUrl = {
    create: "/subscriptions-plans",
    getSubscriptionList: "/subscriptions-plans/by",
    getSubscriptionPlan: "/subscriptions-plans",
    getBaseSubscriptions: "/subscriptions-plans/base-product-list",
    updateStatus: "/subscriptions-plans",
    delete: "/subscriptions-plans",
    getUsers: "/subscriptions-plans",
    editPlan: "/subscriptions-plans",
};

const createPlan = async (payload) => {
    const response = await ApiService.post(subscriptionPlanServiceUrl.create, payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const getSubscriptionList = async () => {
    const response = await ApiService.get(subscriptionPlanServiceUrl.getSubscriptionList);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const getSubscriptionPlan = async (payload) => {
    const response = await ApiService.get(subscriptionPlanServiceUrl.getSubscriptionPlan, payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const getSubscriptionPlanGetUsers = async (id,payload) => {
    const response = await ApiService.get(`${subscriptionPlanServiceUrl.getUsers}/${id}/get-users`,payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const getMultiPkgsList = async (payload) => {
    const response = await ApiService.get(subscriptionPlanServiceUrl.getBaseSubscriptions, payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const updateStatus = async (id, data) => {
    const response = await ApiService.patch(`${subscriptionPlanServiceUrl.updateStatus}/${id}/status`, data);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const editPlan = async (id, data) => {
    const response = await ApiService.patch(`${subscriptionPlanServiceUrl.editPlan}/${id}`, data);
    return ApiRequestUtil.handleApiRequestTwo(response);
};
const deleteSubscription = async (id) => {
    const response = await ApiService.deleteRequest(`${subscriptionPlanServiceUrl.delete}/${id}`);
    return ApiRequestUtil.handleApiRequestTwo(response);
};

export const SubscriptionPlanService = {
    createPlan,
    getSubscriptionList,
    getSubscriptionPlan,
    updateStatus,
    deleteSubscription,
    getSubscriptionPlanGetUsers,
    editPlan,
    getMultiPkgsList
};