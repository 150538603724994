export const authenticatedRoutesConstant = {
    Home: "/",
    Dashboard: "/dashboard",
    UserManagement: "/user-management",
    SubscriptionPlans: "/subscription-plans",
    SubscriptionPlansDetails: "/subscription-plans/detail/:id",
    RefferalCode: "/refferal-code",
    RefferalCodeDetails: "/refferal-code/detail/:id",
};

//login,register
export const unAuthenticatedRoutesConstant = {
    Home: "/",
    Login: "/",
    Register: "/register"
};

export const userInfo = {
    TOKEN: "TOKEN",
    USER: "USER"
};

export const userTypes = {
    user: "USER"
};

export const primaryColor = "#A49786";

export const socialMediaColors = {
    google: "#f46a6a",
    twitter: "#50a5f1",
    facebook: "#556ee6"
};
