import { Col, Row, Table } from "antd";
import { PAGE_SIZE } from "queries/queries.constant";
import { memo, useRef } from "react";
import "./Gridview.less";

const GridView = ({
    data = null,
    className = "",
    filtersRender = false,
    pagination = {
        currentPage: 1,
        pageSize: PAGE_SIZE,
        total: 0,
        onChange: () => {}
    },
    loading = false,
    columns = []
}) => {
    const uniqueId = useRef(0); // Preserves uniqueId across renders

    const handleOnChange = (pagination, filters, sorter = {}) => {
        // Sorting and filtering logic (if needed in future)
        // You can pass this callback to handle sorting/filtering changes
    };

    return (
        <>
            {/* Renders Filters (if provided) */}
            {filtersRender && (
                <div className="topHeader">
                    <Row>
                        <Col span={16}>{filtersRender()}</Col>
                    </Row>
                </div>
            )}

            {/* Main Table View */}
            <div className="custom-table-grid-view-container">
                <Table
                    className={`${className} custom-table-container cardWithStyle`}
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    onChange={handleOnChange}
                    pagination={{
                        current: pagination.currentPage,
                        pageSize: pagination.pageSize,
                        total: pagination.total,
                        onChange: pagination.onChange
                    }}
                    rowKey={(record) => record?.id || ++uniqueId.current} // Generate unique key if id is missing
                />
            </div>
        </>
    );
};

export default memo(GridView);
