import { useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from 'react-phone-number-input'

const { Form, Modal, Row, Col, Input, Button } = require("antd");

const EditDetailModal = ({ isVisible, onFinish, onCancel, initialValues = {}, isLoading }) => {
    const [form] = Form.useForm();
    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };
    useEffect(() => {
        const resetAndSetValue = () => {
            form.resetFields();
            form.setFieldsValue(initialValues);
        };
        resetAndSetValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    return (
        <Modal footer={null} open={isVisible} centered onCancel={handleCancel} width={700}>
            <div className="subscription-plans-container__modal">
                <h2 className="__editSubscriptionTitle">{"Edit Detail"}</h2>
                <Form form={form} name="planForm" onFinish={onFinish} autoComplete="off" layout="vertical">
                    <Row gutter={16} align={"middle"}>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input email!"
                                    }
                                ]}
                                label="Email"
                            >
                                <Input placeholder="Enter Email" type="email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input phone!"
                                    }
                                ]}
                                label="Phone Number"
                            >
                                <PhoneInput international countryCallingCodeEditable={false} defaultCountry="US" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {"Save Changes"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
const ResetPasswordModal = ({ isVisible, onFinish, onCancel = {}, isLoading }) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        onCancel();
        form.resetFields();
    };
    useEffect(() => {
        const resetAndSetValue = () => {
            form.resetFields();
        };
        resetAndSetValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    return (
        <Modal footer={null} open={isVisible} centered onCancel={handleCancel} width={700}>
            <div className="subscription-plans-container__modal">
                <h2 className="__editSubscriptionTitle">{"Reset Password"}</h2>
                <Form form={form} name="planForm" onFinish={onFinish} autoComplete="off" layout="vertical">
                    <Row gutter={16} align={"middle"}>
                        <Col span={24}>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input password!"
                                    }
                                ]}
                                label="Password"
                            >
                                <Input.Password placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {"Save Changes"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
export { EditDetailModal, ResetPasswordModal };

