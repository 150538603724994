import React from "react";
import { Input } from "antd";
import searchIcon from "../../assets/all-svg-files/search.svg";
import "./SearchInput.less";

const SearchInput = ({ onSearch }) => {
    return (
        <div className="SearchInput-container__search">
            <Input
                prefix={<img src={searchIcon} alt="search" />}
                onChange={onSearch}
                placeholder="Search..."
                allowClear
            />
        </div>
    );
};
export default SearchInput;
