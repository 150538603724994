import dayjs from "dayjs";
import { ActionIcons } from "./Components";

export const RefferalCodeColumns = (handleView, handleEdit, handleDelete) => {
    return [
        {
            title: "S. No",
            key: "userId",
            dataIndex: "id"
        },

        {
            title: "Coupon Code",
            key: "couponCode",
            render: (res) => <span>{`${res?.couponCode}`}</span>
        },
        {
            title: "Date Created",
            key: "createdDate",
            render: (res) => <span>{dayjs(res?.createdDate).format("MM-DD-YYYY")}</span>
        },
        {
            title: "Start Date",
            key: "startDate",
            render: (res) => <span>{dayjs(res?.startDate).format("MM-DD-YYYY")}</span>
        },
        {
            title: "End Date",
            key: "endDate",
            render: (res) => <span>{dayjs(res?.endDate).format("MM-DD-YYYY")}</span>
        },
        {
            title: " Eligible Plans",
            key: "eligiblePlans",
            render: (res) => <span>{res?.eligiblePlans}</span>
        },
        {
            title: "Usage",
            key: "perUserLimit",
            render: (res) => <span>{res?.perUserLimit} Times</span>
        },
        {
            title: "Status",
            key: "status",
            render: (res) => <span>{res?.status}</span>
        },
        {
            title: "Actions",
            key: "actions",
            render: (res) => (
                <ActionIcons
                    onView={() => handleView(res.id, res)}
                    onEdit={() => handleEdit(res)}
                    onDelete={() => handleDelete(res.id)}
                />
            )
        }
    ];
};
export const RefferalDetailColumns = () => {
    return [
        {
            title: "S. No",
            key: "userId",
            dataIndex: "id"
        },

        {
            title: "Name",
            key: "name",
            render: (res) => <span>{`${res?.user?.first_name} ${res?.user?.last_name}` || 'N/A'}</span>
        },

        {
            title: "Email",
            key: "email",
            render: (res) => <span>{res?.user?.email || 'N/A'}</span>
        },

        {
            title: "Contact",
            key: "contact",
            render: (res) => <span>{res?.user?.detail?.phone || 'N/A'}</span>
        },
        {
            title: "State",
            key: "state",
            render: (res) => <span>{res?.user?.address[0]?.state?.name || 'N/A'}</span>
        },
        {
            title: "City",
            key: "city",
            render: (res) => <span>{res?.user?.address[0]?.city?.name || 'N/A'}</span>
        }
    ];
};
export const statusOptions = [
    { label: "All", value: "" },
    { label: "In-Active", value: "INACTIVE" },
    { label: "Active", value: "ACTIVE" },
    { label: "Expired", value: "EXPIRED" }
];
export const monthOptions = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" }
];
export const yearOptions = Array.from({ length: 10 }, (_, i) => (2024 - i).toString());