import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { SubscriptionPlanService } from "services/subscriptionplan.service";
import { handleErrorResponse } from "util/errorHandling.util";

const { QueryNames, globalReactQueryGetOption } = require("./queries.constant");

function useQuerySubscriptionData() {
    const queryName = [QueryNames.subscriptionPlanPackagesList];
    const queryFn = async () => await SubscriptionPlanService.getSubscriptionList();

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useQuerySubscriptionPlan(payload) {
    const queryName = [QueryNames.subscriptionPlan, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await SubscriptionPlanService.getSubscriptionPlan(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useQueryMultiPkgsList(payload) {
    const queryName = [QueryNames.getBaseSubscriptions, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 1000 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await SubscriptionPlanService.getMultiPkgsList(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useQuerySubscriptionPlanGetUsers(id, payload) {
    const queryName = [QueryNames.subscriptionPlanByUsers, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await SubscriptionPlanService.getSubscriptionPlanGetUsers(id, queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useQueryUpdateStatus(onSuccess, onError) {
    const queryFn = async ({ payload, id }) => await SubscriptionPlanService.updateStatus(id, payload);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
    // return useMutation(queryFn);
}
// function useMutationCreatePlan() {
//     const queryFn = async ({ payload }) => await SubscriptionPlanService.createPlan(payload);
//     return useMutation(queryFn);
// }

// Create a subscription
function useMutationCreatePlan(onSuccess, onError) {
    const queryFn = async ({ payload }) => await SubscriptionPlanService.createPlan(payload);

    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}
// Delete a subscription
function useMutationDeleteSubscription(onSuccess, onError) {
    const queryFn = async ({ id }) => await SubscriptionPlanService.deleteSubscription(id);

    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}
function useMutationEditPlan(onSuccess, onError) {
    const queryFn = async ({ payload, id }) => await SubscriptionPlanService.editPlan(id, payload);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}

export {
    useMutationCreatePlan, useMutationDeleteSubscription, useMutationEditPlan,
    useQueryMultiPkgsList, useQuerySubscriptionData,
    useQuerySubscriptionPlan, useQuerySubscriptionPlanGetUsers, useQueryUpdateStatus
};

