import { PAGE_SIZE } from "queries/queries.constant";
import { useQuerySubscriptionPlanGetUsers } from "queries/subscription.queries";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { userTypes } from "util/util.constant";
import { SubscriptionPlansDetailColumns } from "../constants";

export default function useSubscriptionPlansDetail() {
    const [search, setSearch] = useState(null);

    const [page, setPage] = useState(1);
    const [filterByStatus] = useState(null);
    const columns = SubscriptionPlansDetailColumns();
    const location = useLocation();
    const id = location.pathname.split("/").pop();
    const additionalData = location.state?.additionalData;

    const userQueryPayload = {
        page,
        isPagination: true,
        type: userTypes.user,
        take: PAGE_SIZE,
        search: search,
        status: filterByStatus
    };
    const { data, isLoading, refetch } = useQuerySubscriptionPlanGetUsers(id, userQueryPayload);
    const handleSearch = (value) => {
        setSearch(value);
    };
    return {
        setPage,
        search,
        columns,
        page,
        additionalData,
        data,
        isLoading,
        refetch,
        handleSearch
    };
}

