import { create } from "apisauce";
import AuthService from "./auth.service";
import { handleErrorResponse } from "./errorHandling.util";

const apiInstance = create({
    baseURL: process.env.REACT_APP_API_URL
});

const get = async (url, queryParams, config) => {
    const response = await apiInstance.get(url, queryParams, config);
    return handleReponse(response);
};

const post = async (url, data, config) => {
    const response = await apiInstance.post(url, data, config);
    return handleReponse(response);
};

const put = async (url, data, config) => {
    const response = await apiInstance.put(url, data, config);
    return handleReponse(response);
};

const patch = async (url, data, config) => {
    const response = await apiInstance.patch(url, data, config);
    return handleReponse(response);
};

const deleteRequest = async (url, queryParams, config) => {
    const response = await apiInstance.delete(url, queryParams, config);
    return handleReponse(response);
};
const handleReponse = (response) => {
    if (!response?.ok) {
        handleErrorResponse(response);
    }
    return response;
};
// const handleReponse = (response) => {
//     if (response?.status === 401) {
//         localStorage.removeItem(userInfo.TOKEN);
//         localStorage.removeItem(userInfo.USERNAME);
//         message.error("Unauthorized");
//         redirect(unAuthenticatedRoutesConstant.Login);
//     }

//     if (response?.status === 500) {
//         message.warn("something went wrong!");
//         return response;
//     }

//     if (!response?.ok) {
//         const errorMessages = response?.data?.message || [];
//         if (typeof errorMessages === 'object') {
//             errorMessages?.forEach((singleErrorMessage) => {
//                 message.warn(singleErrorMessage);
//             });
//         } else {
//             message.warn(errorMessages || "something went wrong!");
//         }
//     }

//     return response;
// };

apiInstance.addRequestTransform((request) => {
    if (AuthService.isTokenExist()) {
        request.headers["Authorization"] = "Bearer " + AuthService.getTokenExist();
        request.headers["ngrok-skip-browser-warning"] = true;
    }
});

const setHeaders = (headers = []) => {
    if (headers.length > 0) {
        headers.forEach((singleHeader) => {
            const { headerKey, headerValue } = singleHeader;
            apiInstance.setHeader(headerKey, headerValue);
        });
    }
};

export const ApiService = {
    get,
    post,
    put,
    patch,
    deleteRequest,
    setHeaders
};

