import { message } from "antd";

export const handleErrorResponse = (error) => {
    if (error) {
        const status = error.status;
        if (status === 401) {
            message.error("Unauthorized access. Please log in again.");
        } else if (status === 500) {
            message.warn("Something went wrong!");
        } else {
            const errorMessages = error.data?.message || "An unexpected error occurred.";
            if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => message.warn(msg));
            } else {
                message.warn(errorMessages);
            }
        }
    } 
};