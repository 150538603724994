/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Input, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import searchIcon from "../../assets/all-svg-files/search-icon.svg";
import twaLogo from "../../assets/images/twa-logo.png";
import user from "../../assets/images/user.png";
import ChangePasswordModal from "Components/ChangePasswordModal/ChangePasswordModal";
import "./HeaderContent.less";
import { authenticatedRoutesConstant, unAuthenticatedRoutesConstant, userInfo } from "util/util.constant";
import AuthService from "util/auth.service";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useDispatch } from "react-redux";
import { updateSearch, updateSelectedKey } from "redux/searchSlice";
import { sidebarKeys } from "routes/RouteConstants";

const HeaderContent = () => {
    const [changePasswordVisible, setChangePasswordVisible] = useState(false);
    const [localSearch, setLocalSearch] = useState("");
    const [debouncedSearch] = useDebounce(localSearch, 500);
    const userData = AuthService.getUserInfo();
    const location = window.location.href.split("/")[window.location.href.split("/").length - 1];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = (event) => {
        event.preventDefault();
        localStorage.removeItem(userInfo.TOKEN);
        localStorage.removeItem(userInfo.USER);
        window.location.href = unAuthenticatedRoutesConstant.Login;
    };

    const handleFocus = () => {
        if (location !== "user-management") {
            navigate("/user-management");
            dispatch(updateSelectedKey(sidebarKeys.userManagement));
        }
    };

    useEffect(() => {
        dispatch(updateSearch(debouncedSearch));
    }, [debouncedSearch]);

    const items = [
        {
            label: (
                <Button className="header-container__btn" onClick={() => setChangePasswordVisible(true)}>
                    Change Password
                </Button>
            ),

            key: "changePassword"
        },
        {
            label: (
                <Button className="header-container__btn" onClick={(event) => logout(event)}>
                    Logout
                </Button>
            ),
            key: "logout"
        }
    ];

    return (
        <div className="header-container">
            <Link to={authenticatedRoutesConstant.Dashboard}>
                <div className="header-container__logo">
                    <img src={twaLogo} alt="logo" />
                </div>
            </Link>

            <div className="header-container__search">
                <Input
                    prefix={<img src={searchIcon} alt="search" />}
                    placeholder="Search..."
                    onFocus={handleFocus}
                    allowClear
                    onChange={(e) => setLocalSearch(e.target.value)}
                    // onChange={(e) => handleChange(e)}
                />
            </div>

            <div className="header-container__profile">
                <div className="header-container__profile-img">
                    <img src={user} alt="user" />
                </div>
                <div className="header-container__profile-information">
                    <Dropdown menu={{ items }} trigger="click">
                        <span className="ml-1 cursor-pointer">
                            {userData.firstName + " " + userData.lastName} <DownOutlined />
                        </span>
                    </Dropdown>
                </div>
            </div>

            <Modal
                className="header-container__modal"
                open={changePasswordVisible}
                onCancel={() => setChangePasswordVisible(false)}
                footer={null}
                centered={true}
            >
                <ChangePasswordModal onCancel={() => setChangePasswordVisible(false)} />
            </Modal>
        </div>
    );
};

export default HeaderContent;
