import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    search: "",
    selectedKey: localStorage.getItem('selectedKey') || "Dashboard",
};

export const searchSlice = createSlice({
    name: "searchParameters",
    initialState,
    reducers: {
        updateSearch: (state, { payload }) => {
            state.search = payload;
        },
        updateSelectedKey: (state, { payload }) => {
            state.selectedKey = payload;
            localStorage.setItem('selectedKey', payload); 
        }
    }
});

export const { updateSearch, updateSelectedKey } = searchSlice.actions;

export default searchSlice.reducer;
