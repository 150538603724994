import React from "react";
import CheckIcon from "../../assets/all-svg-files/check.svg";
import UnCheckIcon from "../../assets/all-svg-files/uncheck.svg";

const CustomCheckbox = ({
    autoFocus = false,
    checked = false,
    disabled = false,
    indeterminate = false,
    onChange,
    children,
    ...rest
}) => {
    const handleChange = (e) => {
        if (disabled) return;
        if (onChange) {
            onChange({
                ...e,
                target: {
                    ...e.target,
                    checked: !checked // Invert the checked state
                }
            });
        }
    };

    return (
        <label
            tabIndex={autoFocus ? 0 : -1}
            role="checkbox"
            aria-checked={indeterminate ? "mixed" : checked}
            onClick={handleChange}
            style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.5 : 1
            }}
            {...rest}
        >
            {checked ? <img src={CheckIcon} alt="checked" /> : <img src={UnCheckIcon} alt="uncheck" />}
            {indeterminate && <img src={UnCheckIcon} alt="checkbox" />}
            <span style={{ marginLeft: 8 }}>{children}</span>
        </label>
    );
};

export default CustomCheckbox;
