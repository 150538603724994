import { ApiRequestUtil } from "util/api.request.util";
import { ApiService } from "util/api.service";

const dashboardServiceUrl = {
    analytics: "/analytics"
};

const getAnalytics = async (payload) => {
    const response = await ApiService.get(dashboardServiceUrl.analytics);
    return ApiRequestUtil.handleApiRequest(response);
};

export const DashboardService = {
    getAnalytics
};
