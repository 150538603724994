import { Button, Col, Row } from "antd";
import DashboardCard from "Components/Card/DashboardCard";
import GridView from "Components/GridView/GridView";
import { useQueryDashboardAnalytics, useQueryUsers } from "queries/dashboard.queries";
import { PAGE_SIZE } from "queries/queries.constant";
import React from "react";
import { Link } from "react-router-dom";
import { authenticatedRoutesConstant, userTypes } from "util/util.constant";
import { cardList, dashboardColumns } from "./constants";
import "./Dashboard.less";

const Dashboard = () => {
    const userQueryPayload = {
        page: 1,
        isPagination: true,
        type: userTypes.user,
        take: PAGE_SIZE
    };
    const { data: users, isLoading: userLoading } = useQueryUsers(userQueryPayload);
    const { data: analytics, isLoading: analyticsLoading } = useQueryDashboardAnalytics();

    const handleClick = () => {
        console.log("something happens");
    };
console.log('users?.data',users?.data);
    const columns = dashboardColumns(handleClick);

    return (
        <div className="dashboard-container">
            <div className="dashboard-container__card-container">
                <Row gutter={[12, 12]}>
                    {cardList.length > 0 &&
                        cardList?.map((cardInfo, index) => (
                            <Col lg={6} md={12} sm={12} xs={24} key={index}>
                                <DashboardCard
                                    cardInfo={cardInfo}
                                    analyticsLoading={analyticsLoading}
                                    analytics={analytics}
                                />
                            </Col>
                        ))}
                </Row>
            </div>
            <div className="dashboard-container__table">
                <GridView
                    title={() => "New Users"}
                    data={users?.data}
                    columns={columns}
                    pagination={false}
                    loading={userLoading}
                />
            </div>
            <div className="dashboard-container__btn-container">
                <Link to={authenticatedRoutesConstant.UserManagement}>
                    <Button className="dashboard-container__btn-container__btn" type="primary">
                        View All
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default Dashboard;
