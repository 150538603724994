/* eslint-disable no-eval */
import _ from "lodash";
import { v4 as uuid } from "uuid";

import dayjs from "dayjs";

const convertDateToOurFormat = (data) => {
    if (!data) {
        return null;
    }
    return dayjs(data).format("MMMM Do YYYY,  h:mm a");
};

function createDynamicUrl(dynamicUrl, object) {
    for (const key in object) {
        dynamicUrl = dynamicUrl.replace(`:${key}`, object[key]);
    }
    return dynamicUrl;
}

function isAfter(currentDate, latestData) {
    return dayjs(currentDate).isBefore(latestData);
}

function createDynamicUrlForApiService(dynamicUrl, object) {
    for (const key in object) {
        const objectKey = object[key] ?? "";
        dynamicUrl = dynamicUrl.replace(`{${key}}`, objectKey);
    }
    return dynamicUrl;
}

function downloadFromUrl(url) {
    const aHref = document.createElement("a");
    aHref.href = url;
    aHref.target = "_blank";
    aHref.download = "download";
    aHref.click();
}

function deepClone(arrayParam) {
    const cloneArray = _.cloneDeep(arrayParam);
    return cloneArray;
}

function urlParameters() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
}

function generateUniqueId() {
    const uniqueId = uuid();
    return uniqueId;
}

function base64toArray(data) {
    const decodedStringAtoB = atob(data);
    const convertedArray = eval(decodedStringAtoB);
    return convertedArray;
}

function filterUniqueArray(duplicateArray = []) {
    const arrStr = duplicateArray.map((item) => JSON.stringify(item));
    return [...new Set(arrStr)].map((item) => JSON.parse(item));
}

function deepfilterUniqueArray(duplicateArray = [], key = "") {
    const arrayUniqueByKey = [...new Map(duplicateArray.map((item) => [item[key], item])).values()];

    return arrayUniqueByKey;
}

function timeFormatFormView(value = "") {
    return dayjs(value).format("hh-mm aa");
}
function dateFormatFormView(value = "") {
    return dayjs(value).format("yyyy-mm-DD");
}

export const UtilService = {
    timeFormatFormView,
    dateFormatFormView,
    filterUniqueArray,
    deepfilterUniqueArray,
    base64toArray,
    deepClone,
    convertDateToOurFormat,
    createDynamicUrl,
    downloadFromUrl,
    createDynamicUrlForApiService,
    isAfter,
    urlParameters,
    generateUniqueId
};
