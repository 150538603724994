import React, { useState } from "react";
import { Button, Form, Input, notification } from "antd";
import "./ChangePasswordModal.less";
import { UserService } from "services/users.service";

const ChangePasswordModal = ({ onCancel }) => {
    const [loader, setLoader] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        setLoader(true);
        const payload = {
            current_password: values.current_password,
            new_password: values.new_password
        };
        const response = await UserService.changePassword(payload);
        form.resetFields();
        if (response) {
            notification.success({
                message: "Password changed successfully!"
            });
        } else {
            notification.error({
                message: "Old password is incorrect!"
            });
        }
        setLoader(false);
        onCancel();
    };

    return (
        <div className="change-password-modal">
            <h1 className="change-password-modal__title text-center">Change Password</h1>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="current_password"
                    label="Old Password"
                    rules={[
                        {
                            required: true,
                            message: "Old Password is Required"
                        }
                    ]}
                >
                    <Input.Password placeholder="Enter Password" />
                </Form.Item>

                <Form.Item
                    name="new_password"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: "New Password is Required"
                        }
                    ]}
                >
                    <Input.Password placeholder="Enter Password" />
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    rules={[
                        {
                            required: false,
                            message: "Please confirm your password!"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("new_password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("The confirm password that you entered do not match!"));
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="Enter Password" />
                </Form.Item>

                <Button htmlType="submit" type="primary" loading={loader} className="change-password-modal__submit">
                    Confirm Password
                </Button>
            </Form>
        </div>
    );
};

export default ChangePasswordModal;
