import { Layout, Menu } from "antd";
import HeaderContent from "Components/HeaderContent/HeaderContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { updateSelectedKey } from "redux/searchSlice";
import { sidebarItems } from "routes/RouteConstants";
import "./CustomLayout.less";

const { Header, Content, Sider } = Layout;
const CustomLayout = () => {
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(false);
    const selectedKey = useSelector((state) => state.search.selectedKey);

    const dispatch = useDispatch();
    useEffect(() => {
        const pathSegments = location.pathname.split("/").filter(Boolean); // Filter to remove any empty segments
        dispatch(updateSelectedKey(pathSegments[0]));
    }, [location, dispatch]);

    return (
        <Layout className="custom-layout-container">
            <Header className="custom-layout-container__header">
                <HeaderContent />
            </Header>

            <Layout className="site-layout">
                <Sider
                
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    width="25rem"
                    breakpoint="md"
                    theme="dark"
                    className="site-layout__sider"
                >
                    <Menu
                    defaultSelectedKeys={["Dashboard"]}
                        theme="light"
                        selectedKeys={selectedKey}
                        mode="inline"
                        items={sidebarItems}
                        className="site-layout__menu"
                        onSelect={(e) => dispatch(updateSelectedKey(e.key))}
                    />
                </Sider>
                <Content className="custom-main-content">
                    <div className="site-layout-background  custom-inner-content">
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};
export default CustomLayout;

