import { useMutation, useQuery } from "react-query";
import { RefferalCodeService } from "services/reffferalCode.service";
import { globalReactQueryGetOption, QueryNames } from "./queries.constant";
import { useMemo } from "react";
import { handleErrorResponse } from "util/errorHandling.util";

function useMutationCreateRefferalCode(onSuccess, onError) {
    const queryFn = async ({ payload }) => await RefferalCodeService.CreateRefferalCode(payload);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}
function useQueryEligiblePlansList() {
    const queryName = [QueryNames.refferalCodeEligiblePlans];
    const queryFn = async () => await RefferalCodeService.EligiblePlansList();

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useQueryEligibleChildPlansList(id) {
    const queryName = [QueryNames.refferalCodeEligibleChildPlans, id];
    const queryFn = async () => await RefferalCodeService.EligibleChildPlansListing(id);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true,
        enabled: !!id // Ensures the query only runs when an ID is provided
    });

    return queryReturnedValues;
}
function useQueryRefferalList(payload) {
    const queryName = [QueryNames.getRefferalList, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await RefferalCodeService.RefferalList(queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useMutationDeleteRefferalDetail(onSuccess, onError) {
    const queryFn = async ({ id }) => await RefferalCodeService.deleteRefferalDetailItem(id);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}
function useQueryRefferalDetailByID(id, payload) {
    const queryName = [QueryNames.RefferalDetailByID, payload?.page];
    const queryFnParams = useMemo(() => {
        return {
            ...(!payload?.isPagination
                ? { take: 10 }
                : {
                      ...payload
                  })
        };
    }, [payload]);
    const queryFn = async () => await RefferalCodeService.RefferalDetailByID(id, queryFnParams);

    const queryReturnedValues = useQuery(queryName, queryFn, {
        ...globalReactQueryGetOption,
        keepPreviousData: true
    });

    return queryReturnedValues;
}
function useMutationEditRefferal(onSuccess, onError) {
    const queryFn = async ({ payload, id }) => await RefferalCodeService.editRefferal(id, payload);
    return useMutation(queryFn, {
        onError: (error) => {
            handleErrorResponse(error);
            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}
export {
    useMutationCreateRefferalCode,
    useQueryEligiblePlansList,
    useQueryRefferalList,
    useMutationDeleteRefferalDetail,
    useQueryRefferalDetailByID,
    useMutationEditRefferal,
    useQueryEligibleChildPlansList
};

