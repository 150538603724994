import GridView from "Components/GridView/GridView";
import SearchInput from "Components/SearchInput/SearchInput";
import { PAGE_SIZE } from "queries/queries.constant";
import { useEffect } from "react";
import { userTypes } from "util/util.constant";
import { CardViewDetail } from "../Components";
import "./RefferalCodeDetail.less";
import useRefferalCodeDetailContainer from "./RefferalCodeDetailContainer";

function RefferalCodeDetail() {
    const {
        columns,
        search,
        setPage,
        additionalData,
        data,
        isLoading,
        refetch,
        handleSearch,
    } = useRefferalCodeDetailContainer();

    useEffect(() => {
        setPage(1);
        let userQueryPayload = {
            page:1,
            isPagination: true,
            type: userTypes.user,
            take: PAGE_SIZE,
            search: search
        };
        refetch(userQueryPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    return (
        <div className="Refferal-code-detail-container">
            <CardViewDetail {...additionalData} title={"Details Referral Code"} />
            <SearchInput onSearch={({ target }) => handleSearch(target.value)} />

            <div className="Refferal-code-detail-container__table">
                <GridView
                    title={() => "Redemption History"}
                    data={data?.data}
                    loading={isLoading}
                    columns={columns}
                    pagination={{
                        currentPage: data?.currentPage,
                        pageSize: PAGE_SIZE,
                        total: data?.totalCount,
                        onChange: setPage
                    }}
                />
            </div>
            {/* <div className="dashboard-container__btn-container">
                <Link to={authenticatedRoutesConstant.RefferalCode}>
                    <Button className="dashboard-container__btn-container__btn" type="primary">
                        Back
                    </Button>
                </Link>
            </div> */}
        </div>
    );
}
export default RefferalCodeDetail;

