import { ApiRequestUtil } from "util/api.request.util";
import { ApiService } from "util/api.service";

const userServiceUrl = {
    login: "/login",
    register: "/register",
    getUsers: "/users",
    changePassword: "/change-password",
    adminResetPassword: "/admin/reset-password"
};

const login = async (data) => {
    const response = await ApiService.post(userServiceUrl.login, data);
    return ApiRequestUtil.handleApiRequest(response);
};

const register = async (data) => {
    const response = await ApiService.post(userServiceUrl.register, data);
    return ApiRequestUtil.handleApiRequest(response);
};

const getUsers = async (payload) => {
    const response = await ApiService.get(userServiceUrl.getUsers, payload);
    return ApiRequestUtil.handleApiRequestTwo(response);
};

const updateUser = async (id, data) => {
    const response = await ApiService.patch(`${userServiceUrl.getUsers}/${id}`, data);
    return ApiRequestUtil.handleApiRequest(response);
};
const updateGuestDetail = async (userId, data) => {
    const response = await ApiService.patch(`${userServiceUrl.getUsers}/${userId}`, data);
    return ApiRequestUtil.handleApiRequest(response);
};
const updateUsersPassword = async (userId, data) => {
    const response = await ApiService.patch(`${userServiceUrl.adminResetPassword}/${userId}`, data);
    return ApiRequestUtil.handleApiRequest(response);
};

const changePassword = async (data) => {
    const response = await ApiService.post(userServiceUrl.changePassword, data);
    return ApiRequestUtil.handleApiRequest(response);
};

export const UserService = {
    login,
    register,
    getUsers,
    changePassword,
    updateUser,
    updateGuestDetail,
    updateUsersPassword
};
