import { userInfo } from "./util.constant";

function isTokenExist() {
    const token = localStorage.getItem(userInfo.TOKEN);
    if (token) {
        return true;
    }
    return false;
}

function getTokenExist() {
    const token = localStorage.getItem(userInfo.TOKEN);
    if (token) {
        return token;
    }
    return null;
}

function getUserInfo() {
    const user = localStorage.getItem(userInfo.USER);
    if (user) {
        return JSON.parse(user);
    }
    return null;
}

const AuthService = {
    isTokenExist,
    getUserInfo,
    getTokenExist
};

export default AuthService;

