import { useRoutes } from "react-router-dom";
import AuthService from "./util/auth.service";
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "routes/RouteConstants";
import "./assets/vendors/style";
import "./App.less";

function App() {
    const conditionRoutes = AuthService.isTokenExist() ? [...AUTHENTICATED_ROUTES] : [...UNAUTHENTICATED_ROUTES];

    let element = useRoutes(conditionRoutes);
    return <div className="App">{element}</div>;
}

export default App;
