import React from "react";
import { primaryColor } from "util/util.constant";
import "./IconCircle.less";

const IconCircle = ({
    icon = null,
    alt = "icon",
    height = "3.2rem",
    width = "3.2rem",
    backgroundColor = { primaryColor },
    className = "",
    onClickHandler = () => null
}) => {
    return (
        <div
            className={`icon-container ${className}`}
            onClick={onClickHandler}
            style={{
                height,
                width,
                backgroundColor
            }}
        >
            <img src={icon} alt={alt} />
        </div>
    );
};

export default IconCircle;
